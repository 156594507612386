export const adminNavItems = [
	{
		name: 'Ensayos',
		url: '/trial',
		icon: 'fa fa-user-md',
	},
	{
		name: 'Síntomas',
		url: '/symptom',
		icon: 'fa fa-heartbeat',
	},
	{
		name: 'Medicamentos',
		url: '/medicine',
		icon: 'fa fa-medkit',
	},
	{
		name: 'Preguntas',
		url: '/question',
		icon: 'fa fa-question-circle',
	},
	{
		name: 'Centros',
		url: '/center',
		icon: 'fa fa-hospital-o',
	},
	{
		name: 'Recursos sanitarios',
		url: '/resource',
		icon: 'fa fa-stethoscope',
	},
	{
		name: 'Usuarios internos',
		url: '/user_internal',
		icon: 'fa fa-id-badge',
	},
	{
		name: 'Usuarios externos',
		url: '/user_external',
		icon: 'fa fa-users',
	},
	{
		name: 'Idiomas',
		url: '/translation',
		icon: 'fa fa-language',
	},
];

export const doctorNavItems = {
	es: [
		{
			name: 'Ensayos',
			url: '/my/trial',
			icon: 'fa fa-user-md',
		},
	],
	en: [
		{
			name: 'Studies',
			url: '/my/trial',
			icon: 'fa fa-user-md',
		},
	],
};

export const externalUserNavItems = {
	es: [
		{
			name: 'Ensayos',
			url: '/external/trial',
			icon: 'fa fa-user-md',
		},
	],
	en: [
		{
			name: 'Studies',
			url: '/external/trial',
			icon: 'fa fa-user-md',
		},
	],
};

export const internalUserNavItems = {
	es: [
		{
			name: 'Ensayos',
			url: '/internal/trial',
			icon: 'fa fa-user-md',
		},
	],
	en: [
		{
			name: 'Studies',
			url: '/internal/trial',
			icon: 'fa fa-user-md',
		},
	],
};
