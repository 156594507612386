import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { adminNavItems, doctorNavItems, externalUserNavItems, internalUserNavItems } from './../../_nav';

import { Constants } from '../../util/constants';
import { AuthRepository } from '../../../data/repository/auth.repository';
import { UserRepository } from '../../../data/repository/user.repository';

@Component({
	selector: 'app-dashboard',
	templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent {
	public navItems = [];
	public sidebarMinimized = true;
	private changes: MutationObserver;
	public element: HTMLElement = document.body;

	constructor(
		private authRepository: AuthRepository,
		private userRepository: UserRepository,
		private router: Router,
		private translateService: TranslateService
	) {
		this.authRepository.getCurrentUser().then((user: any) => {
			this.getNavForUser(user);
		});

		this.changes = new MutationObserver((mutations) => {
			this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
		});

		this.changes.observe(<Element>this.element, {
			attributes: true,
		});
	}

	getNavForUser(user): void {
		if (user) {
			this.userRepository
				.getUserInfo(user.uid)
				.then((user) => {
					if (user != null) {
						if (user.type == Constants.USER_TYPE_ADMIN) this.navItems = adminNavItems;
						else if (user.type == Constants.USER_TYPE_DOCTOR)
							this.navItems = Constants.USER_LANGUAGE == 'en' ? doctorNavItems.en : doctorNavItems.es;
						else if (user.type == Constants.USER_TYPE_EXTERNAL_USER)
							this.navItems = Constants.USER_LANGUAGE == 'en' ? externalUserNavItems.en : externalUserNavItems.es;
						else if (user.type == Constants.USER_TYPE_INTERNAL_USER)
							this.navItems = Constants.USER_LANGUAGE == 'en' ? internalUserNavItems.en : internalUserNavItems.es;
						else this.navItems = [];
					} else {
						// doc.data() will be undefined in this case
						console.log('No such document!');
						this.navItems = [];
					}
				})
				.catch(function (error) {
					console.log('Error getting document:', error);
					this.navItems = [];
				});
		} else {
			this.navItems = [];
		}
	}

	logout(): void {
		this.authRepository
			.logout()
			.then(() => {
				this.router.navigate(['/auth/login']);
				this.navItems = [];
			})
			.catch(function (error) {
				// An error happened.
			});
	}
}
