import { Injectable } from "@angular/core";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Participant } from "../model/participant";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable()
export class ExcelService {
  constructor() {}

  public exportDiaryExcel(
    jsonArray: any[],
    participants: Participant[],
    excelFileName: string
  ): void {
    let sheets = {};
    participants.forEach((participant, index) => {
      let changedArray = [];
      jsonArray[index].forEach((element) => {
        let newElement = {};
        newElement["Síntomas"] = element["0"];
        delete element["0"];
        Object.keys(element).forEach((key) => {
          newElement[key] = element[key];
        });

        changedArray.push(newElement);
      });
      changedArray = changedArray.slice(1);

      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(changedArray);
      sheets[participants[index].login_id] = worksheet;
      console.log("XLS", changedArray);
    });

    const workbook: XLSX.WorkBook = {
      Sheets: sheets,
      SheetNames: participants.map((participant) => participant.login_id),
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportBiocleveExcel(
    jsonArray: any[],
    participants: Participant[],
    excelFileName: string
  ): void {
    console.log("jsonArray", jsonArray, jsonArray[0]);
    let sheets = {};
    participants.forEach((participant, index) => {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([
        jsonArray[index],
      ]);
      sheets[participant.login_id] = worksheet;
    });

    const workbook: XLSX.WorkBook = {
      Sheets: sheets,
      SheetNames: participants.map((participant) => participant.login_id),
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}
