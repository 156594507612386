import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthDataStore } from '../datastore/auth.datastore';
import { Constants } from '../../app/util/constants';
import { AuthFirebase } from '../firebase/auth.firebase';
import { AuthApi } from '../api/auth.api';

@Injectable({
	providedIn: 'root',
})
export class AuthRepository {
	private dataStore: AuthDataStore;

	constructor(private http: HttpClient) {
		this.dataStore = Constants.REPOSITORY_WITH_FIREBASE ? new AuthFirebase() : new AuthApi(http);
	}

	getCurrentUser(): any {
		return this.dataStore.getCurrentUser();
	}

	login(email: string, password: string): Promise<any> {
		return this.dataStore.login(email, password);
	}

	logout(): Promise<any> {
		return this.dataStore.logout();
	}

	sendPasswordResetEmail(email: string): Promise<any> {
		return this.dataStore.sendPasswordResetEmail(email);
	}
}
