import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
declare var firebase: any;

import { Constants } from '../util/constants';
import { AuthRepository } from '../../data/repository/auth.repository';
import { UserRepository } from '../../data/repository/user.repository';

@Injectable()
export class ExternalUserAuthGuard implements CanActivate {
	constructor(private authRepository: AuthRepository, private userRepository: UserRepository, private router: Router) {}

	canActivate(): Promise<boolean> {
		return new Promise(async (resolve) => {
			try {
				let user = await this.authRepository.getCurrentUser();
				if (user) {
					const fullUser = await this.userRepository.getUserInfo(user.uid);
					if (fullUser != null && fullUser.type == Constants.USER_TYPE_EXTERNAL_USER) {
						return resolve(true);
					} else {
						return this.goToLoginAndResolve(resolve);
					}
				} else {
					return this.goToLoginAndResolve(resolve);
				}
			} catch (e) {
				return this.goToLoginAndResolve(resolve);
			}
		});
	}

	goToLoginAndResolve(resolve): void {
		this.router.navigate(['/auth/login']);
		resolve(false);
	}
}
