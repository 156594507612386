import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';

export const routes: Routes = [
	{
		path: '',
		redirectTo: '/trial',
		pathMatch: 'full',
	},
	{
		path: 'my',
		redirectTo: '/my/trial',
		pathMatch: 'full',
	},
	{
		path: 'external',
		redirectTo: '/external/trial',
		pathMatch: 'full',
	},
	{
		path: 'internal',
		redirectTo: '/internal/trial',
		pathMatch: 'full',
	},
	{
		path: '404',
		component: P404Component,
		data: {
			title: 'Page 404',
		},
	},
	{
		path: '500',
		component: P500Component,
		data: {
			title: 'Page 500',
		},
	},
	{
		path: 'auth',
		loadChildren: './views/auth/auth.module#AuthModule',
	},
	{
		path: '',
		component: DefaultLayoutComponent,
		data: {
			title: 'Home',
		},
		children: [
			{
				path: 'trial',
				loadChildren: './views/trial/trial.module#TrialModule',
			},
			{
				path: 'symptom',
				loadChildren: './views/symptom/symptom.module#SymptomModule',
			},
			{
				path: 'medicine',
				loadChildren: './views/medicine/medicine.module#MedicineModule',
			},
			{
				path: 'question',
				loadChildren: './views/question/question.module#QuestionModule',
			},
			{
				path: 'participant/:id/:code/:login_code',
				loadChildren: './views/participant/participant.module#ParticipantModule',
			},
			{
				path: 'diary/:trial_id/diary/:participant_id',
				loadChildren: './views/diary/diary.module#DiaryModule',
			},
			{
				path: 'center',
				loadChildren: './views/center/center.module#CenterModule',
			},
			{
				path: 'doctor/:center_id',
				loadChildren: './views/doctor/doctor.module#DoctorModule',
			},
			{
				path: 'my/trial',
				loadChildren: './doctor_views/trial/trial.module#DoctorTrialModule',
			},
			{
				path: 'external/trial',
				loadChildren: './external_user_views/external-trial.module#ExternalTrialModule',
			},
			{
				path: 'internal/trial',
				loadChildren: './internal_user_views/internal-trial.module#InternalTrialModule',
			},
			{
				path: 'resource',
				loadChildren: './views/resource/resource.module#ResourceModule',
			},
			{
				path: 'user_internal',
				loadChildren: './views/internal-user/internal-user.module#InternalUserModule',
			},
			{
				path: 'user_external',
				loadChildren: './views/external-user/external-user.module#ExternalUserModule',
			},
			{
				path: 'translation',
				loadChildren: './views/translation/translation.module#TranslationModule',
			},
			{
				path: 'admin',
				loadChildren: './views/admin/admin.module#AdminModule',
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
