import { Constants } from '../../app/util/constants';
import { UserDataStore } from '../datastore/user.datastore';

export class UserFirebase implements UserDataStore {
	getUserInfo(userId: string): Promise<any> {
		return new Promise((resolve, reject) => {
			var db = Constants.firestore();
			var docRef = db.collection(Constants.USERS_TABLE).doc(userId);

			docRef
				.get()
				.then((doc) => {
					if (doc.exists) {
						resolve(doc.data());
					}
					reject(null);
				})
				.catch(function (error) {
					reject(error);
				});
		});
	}
}
