import { HttpClient } from '@angular/common/http';
import { AuthDataStore } from '../datastore/auth.datastore';
import { Constants } from '../../app/util/constants';

export class AuthApi implements AuthDataStore {
	constructor(private http: HttpClient) {}

	getCurrentUser(): any {
		return new Promise((resolve, reject) => {
			let token = window.localStorage.getItem(Constants.REPOSITORY_AUTH_TOKEN);
			let uid = window.localStorage.getItem(Constants.REPOSITORY_AUTH_UID);
			let email = window.localStorage.getItem(Constants.REPOSITORY_AUTH_EMAIL);
			resolve({ token: token, uid: uid, email: email });
		});
	}

	logout(): Promise<any> {
		return new Promise((resolve, reject) => {
			try {
				let res = this.http.post(Constants.baseUrl + '/api/v1/auth/logout', {}).toPromise();
			} catch (e) {}
			window.localStorage.removeItem(Constants.REPOSITORY_AUTH_TOKEN);
			window.localStorage.removeItem(Constants.REPOSITORY_AUTH_UID);
			window.localStorage.removeItem(Constants.REPOSITORY_AUTH_EMAIL);
			resolve(true);
		});
	}

	login(email: string, password: string): Promise<any> {
		return new Promise(async (resolve, reject) => {
			try {
				let res: any = await this.http
					.post(Constants.baseUrl + '/api/v1/auth/login', {
						username: email,
						password: password,
					})
					.toPromise();

				window.localStorage.setItem(Constants.REPOSITORY_AUTH_UID, res.uid);
				window.localStorage.setItem(Constants.REPOSITORY_AUTH_TOKEN, res.token);
				window.localStorage.setItem(Constants.REPOSITORY_AUTH_EMAIL, email);

				resolve({ user: res });
			} catch (e) {
				console.log('Error login', e);
				reject(e);
			}
		});
	}

	sendPasswordResetEmail(email: string): Promise<any> {
		return new Promise((resolve, reject) => {
			try {
				let res = this.http.post(Constants.baseUrl + '/api/v1/auth/reset-password', { email: email }).toPromise();
				resolve(res);
			} catch (e) {
				reject(e);
			}
		});
	}
}
