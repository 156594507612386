import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../../app/util/constants';
import { UserFirebase } from '../firebase/user.firebase';
import { UserApi } from '../api/user.api';
import { UserDataStore } from '../datastore/user.datastore';

@Injectable({
	providedIn: 'root',
})
export class UserRepository {
	private dataStore: UserDataStore;

	constructor(private http: HttpClient) {
		this.dataStore = Constants.REPOSITORY_WITH_FIREBASE ? new UserFirebase() : new UserApi(http);
	}

	getUserInfo(id: string): Promise<any> {
		return this.dataStore.getUserInfo(id);
	}
}
