import { HttpClient } from '@angular/common/http';
import { UserDataStore } from '../datastore/user.datastore';
import { Constants } from '../../app/util/constants';

export class UserApi implements UserDataStore {
	constructor(private http: HttpClient) {}

	getUserInfo(id: string): Promise<any> {
		return new Promise(async (resolve, reject) => {
			try {
				let res: any = await this.http.get(Constants.baseUrl + '/api/v1/user/' + id).toPromise();
				resolve(res);
			} catch (e) {
				console.log('Error login', e);
				reject(e);
			}
		});
	}
}
