import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from './util/constants';

@Component({
	// tslint:disable-next-line
	selector: 'body',
	template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
	constructor(private router: Router, translate: TranslateService) {
		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang('en');

		let browserLang = translate.getBrowserLang();
		let lang = browserLang.match(/en|es/) ? browserLang : 'en';
		translate.use(lang);
		Constants.USER_LANGUAGE = lang;
	}

	ngOnInit() {
		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			window.scrollTo(0, 0);
		});
	}
}
