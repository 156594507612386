import { Constants } from '../../app/util/constants';
import { AuthDataStore } from '../datastore/auth.datastore';

declare var firebase: any;

export class AuthFirebase implements AuthDataStore {
	getCurrentUser(): any {
		return new Promise(async (resolve, reject) => {
			Constants.auth().onAuthStateChanged((user) => {
				resolve(user);
			});
		});
	}

	logout(): Promise<any> {
		return Constants.auth().signOut();
	}

	login(email: string, password: string): Promise<any> {
		return new Promise(async (resolve, reject) => {
			try {
				resolve(await Constants.auth().signInWithEmailAndPassword(email, password));
			} catch (e) {
				reject(e);
			}
		});
	}

	sendPasswordResetEmail(email: string): Promise<any> {
		return Constants.auth().sendPasswordResetEmail(email);
	}
}
